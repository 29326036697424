import React from "react";
import { SEO, PageLayout, HeroElement, ContactForm } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class SaladDressingPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Salad Dressing"}
              tagline={"WANT OUR SALAD DRESSING DELIVERED TO YOU?"}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column computer={12} mobile={16}>
              <p>
                You can find Feta’s Salad Dressing at all Feta’s Greek
                restaurants and local (grocery stores) or order via the form
                below! 3 bottle minimum. We can Fed Ex the dressing to you!
              </p>
              <ContactForm
                buttonText={"Submit"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Submit A Request"}
                withContainer={true}
                termsCopy={""}
                subheader={""}
                showLabels={true}
                emailSubject={"Salads Dressing Request"}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Salad Dressing" }) {
      title
    }
  }
`;
